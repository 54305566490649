<template>
  <div ref="liveQuotesWidgetContainer" class="tradingview-widget-container">
    <div class="tradingview-widget-container__widget"></div>
    <div class="tradingview-widget-copyright">
      <a
        href="https://www.tradingview.com/markets/"
        rel="noopener"
        target="_blank"
        ><span class="blue-text">Financial Markets</span></a
      >
      by TradingView
    </div>
  </div>
</template>

<script>
import { PROP_TYPES, propValidator } from '@/utils/validators'

const TV_POLL_TIMEOUT = 1000 * 60

const LIVE_QUOTES_SCRIPT_SRC =
  'https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js'

const LIVE_QUOTES_WIDGET_BASE_CONFIG = {
  colorTheme: 'dark',
  dateRange: '1D',
  showFloatingTooltip: true,
  showChart: true,
  locale: 'en',
  isTransparent: false,
  showSymbolLogo: true,
  plotLineColorGrowing: 'rgba(41, 98, 255, 1)',
  plotLineColorFalling: 'rgba(41, 98, 255, 1)',
  gridLineColor: 'rgba(240, 243, 250, 0)',
  scaleFontColor: 'rgba(106, 109, 120, 1)',
  belowLineFillColorGrowing: 'rgba(41, 98, 255, 0.12)',
  belowLineFillColorFalling: 'rgba(41, 98, 255, 0.12)',
  belowLineFillColorGrowingBottom: 'rgba(41, 98, 255, 0)',
  belowLineFillColorFallingBottom: 'rgba(41, 98, 255, 0)',
  symbolActiveColor: 'rgba(41, 98, 255, 0.12)',
  customer: 'financemagnates'
}

const DEFAULT_WIDGET_WIDTH = '100%'
const DEFAULT_WIDGET_HEIGHT = '1100'

export default {
  name: 'ALiveQuotesWidget',
  props: {
    tabsConfiguration: propValidator([PROP_TYPES.ARRAY]),
    width: propValidator([PROP_TYPES.STRING], false, DEFAULT_WIDGET_WIDTH),
    height: propValidator([PROP_TYPES.STRING], false, DEFAULT_WIDGET_HEIGHT)
  },
  methods: {
    createLiveQuotesWidget() {
      this.$helper.pollUntil({
        fn: () => {
          const widgetContainer = this.$refs.liveQuotesWidgetContainer
          const script = document.createElement('script')
          const widgetConfig = {
            ...LIVE_QUOTES_WIDGET_BASE_CONFIG,
            largeChartUrl: `${this.$env.DOMAIN_URL}/LiveCharts`,
            width: this.width,
            height: this.height,
            tabs: this.tabsConfiguration
          }

          script.type = 'text/javascript'
          script.defer = true
          script.src = LIVE_QUOTES_SCRIPT_SRC
          script.innerHTML = JSON.stringify(widgetConfig)
          widgetContainer.appendChild(script)
        },
        condition: () => !!this.$refs.liveQuotesWidgetContainer,
        timeout: TV_POLL_TIMEOUT
      })
    }
  },
  mounted() {
    this.createLiveQuotesWidget()
  }
}
</script>

<style lang="scss" scoped>
@import '~assets/scss/local/styles/_widget-page.scss';

.tradingview-widget-container__widget {
  min-height: 1068px; // real size
}
</style>
