<template>
  <div class="sidebar-live-quotes__wrapper opened-bracket closed-bracket">
    <div class="sidebar-live-quotes__content-wrapper">
      <a-banner
        v-if="isPageWithAds"
        :banner-settings="$options.consts.BANNER_SETTINGS.SIDEBAR.START_TRADING"
        class="sidebar-live-quotes__button"
      />
      <div v-if="isWidgetVisible" class="sidebar-live-quotes__widget-container">
        <a-live-quotes-widget
          id="tradingview-widget-container-sidebar"
          :tabs-configuration="
            $options.consts.SIDEBAR_WIDGET_TABS_CONFIGURATION
          "
          width="100%"
          height="600"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ABanner from 'shared/ABanner'
import { BANNER_SETTINGS } from 'enums/banners/banner-settings'
import { COOKIE_ENTITY_TYPE } from 'enums/oneTrust'
import { generateServerCacheKey } from '@/plugins/helper'
import { createOneTrustHandlerInstance } from 'enums/oneTrust/one-trust-handler'
import { SIDEBAR_WIDGET_TABS_CONFIGURATION } from 'enums/live-widgets/tabs'
import ALiveQuotesWidget from 'shared/TradingViewWidgets/ALiveQuotesWidget'
import { PROP_TYPES, propValidator } from '@/utils/validators'

export default {
  name: 'LiveQuotes',
  serverCacheKey: () => generateServerCacheKey('LiveQuotes'),
  components: { ABanner, ALiveQuotesWidget },
  props: {
    isPageWithAds: propValidator([PROP_TYPES.BOOLEAN], false, false)
  },
  consts: {
    BANNER_SETTINGS,
    COOKIE_ENTITY_TYPE,
    SIDEBAR_WIDGET_TABS_CONFIGURATION
  },
  data() {
    return {
      isWidgetVisible: false
    }
  },
  methods: {
    ...mapActions({
      registerHandlerThatRequiresConsent:
        'one-trust/registerHandlerThatRequiresConsent'
    }),
    init() {
      this.isWidgetVisible = true
    }
  },
  mounted() {
    this.registerHandlerThatRequiresConsent(
      createOneTrustHandlerInstance({
        handler: this.init,
        entityType: COOKIE_ENTITY_TYPE.LIVE_CHARTS_WIDGETS
      })
    )
  }
}
</script>

<style lang="scss">
.sidebar-live-quotes__wrapper {
  .sidebar-live-quotes__button {
    width: 240px;
    margin: 12px auto 30px;
  }

  .sidebar-live-quotes__widget-container {
    padding: 0 20px;

    @include sidebar-small-size {
      padding: 0;
    }
  }
}
</style>
